import { model } from '@telekomconsalting/dex-guru-model'
import { SubmitHandler } from 'react-hook-form'

import { AMMConfig } from '../../config/amm'
import { FilterDateRecord, FilterOptions, TokenId, TotalValueRecord } from '../../model'

export enum FilterFields {
  amm = 'amm',
  txType = 'transaction_types',
  totalValue = 'totalValue',
  account = 'account',
  poolAddress = 'pool_address',
  // it ui stuff, casting to `wallet_category` in fetch transactions method
  traderCategory = 'trader_category',
  liquidityProviderCategory = 'liquidity_category',
  date = 'date',
  network = 'network',
  secondAccount = 'second_account',
  tokenId = 'token_id',
  tokenStatus = 'token_status',
}

export type FilterFormFields = {
  [FilterFields.amm]?: AMMConfig
  [FilterFields.txType]?: string
  [FilterFields.date]?: FilterDateRecord
  [FilterFields.totalValue]?: TotalValueRecord
  [FilterFields.account]?: string
  [FilterFields.secondAccount]?: string
  [FilterFields.poolAddress]?: string
  [FilterFields.traderCategory]?: Array<model.TraderCategoryName>
  [FilterFields.liquidityProviderCategory]?: Array<model.LiquidityProviderCategoryName>
  [FilterFields.tokenId]?: TokenId
  [FilterFields.network]?: string
  [FilterFields.secondAccount]?: string
  [FilterFields.tokenStatus]?: string
}

export type FilterFromProps = {
  resetOptionFilterProps: () => void
  currentNetwork?: string
  filters: FilterOptions
  isAppliedFilter: boolean
  isMarketTypeLP?: boolean
  offsetLeft?: number
  currentTab?: string
  onClose: () => void
  onSubmit: SubmitHandler<FilterFormFields>
}
