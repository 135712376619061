import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { setIsOnboardingVisible } from '../actions'
import { appGitVersion } from '../config/settings'
import { AmplitudeEvent } from '../constants/amplitudeEvents'
import amplitudeService from '../services/amplitudeService'

interface FooterProps {
  isMobile: boolean
  isDashboard?: boolean
}

const Footer: FC<FooterProps> = (props) => {
  const reduxDispatch = useDispatch()

  const openOnboarding = (e: React.MouseEvent): void => {
    e.preventDefault()
    amplitudeService.sendEvent(AmplitudeEvent.OPENED_USER_ONBOARDING)
    reduxDispatch(setIsOnboardingVisible(true))
  }

  if (props.isMobile) {
    return null
  }

  const year = new Date().getFullYear()

  return (
    <div className="footer">
      <div className="footer__header">
        <div className="footer-meta">
          <span className="footer__copyright">© {year} dex.guru</span>
          <span className="footer__version">Version Beta {appGitVersion}</span>
        </div>
      </div>
      <div className="footer__links footer-links">
        <ul className="footer-links__list">
          <li className="footer-links__item">
            <a
              href="https://docs.dex.guru/legal/terms-of-service"
              target="_blank"
              rel="noopener noreferrer">
              Terms of Service
            </a>
          </li>
          {props.isDashboard && (
            <li id="reopen-onboarding" className="footer-links__item">
              <a
                href="/"
                role="button"
                onClick={openOnboarding}
                className="footer-links__icon footer-links__icon--onboarding">
                <span className="caption">?</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Footer
